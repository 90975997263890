<template >
    <div class="home">
      <v-img
          class="brighten-v-img private "
          src="../assets/home_1280.webp"
          :srcset="`${require('../assets/home_1280.webp')}, ${require('../assets/home_900.webp')} 900w, ${require('../assets/home_400.webp')} 400w`"
      >
        <div class="container">
          <v-col align-content="center" justify="center" style="border-radius: 40px;background-color: #00000080;">
            <div class="row" align-content="center" justify="center">
              <div class="col"></div>
            </div>
            <div class="text-center" >
              <div class=" white--text" style="visibility: visible;">
                <h1 class="">
                  Are you a talented private chef starting out or looking to expand your business and reach more clients?
                </h1>
                <h3 class="">
                  Our private chef platform offers a range of features and advantages that can help you take your business to the next level.
                </h3>

              </div>

            </div>
          </v-col>
          <v-row align-content="center" justify="center" class="margin-top-bottom-big">
            <v-btn @click.capture="openSignup(true)">Signup as a chef</v-btn>
          </v-row>
        </div>
      </v-img>

      <div>
        <v-container fuild class="container margin-top-bottom-big">
          <v-row align-content="center" justify="space-around" class="primary--text text-h5" >
            <div dark>
              <h3 class="secondary-color-text">Bonus up to $200 for the first caterers to register *</h3>
              <br>
            </div>
          </v-row>
          <v-row align-content="center" justify="space-around" class="primary--text text-h5" >
            <div>
              <h2>Key features</h2>
              <br>
            </div>
          </v-row>

<!--          <v-row align-content="center" justify="space-around" class="text-h6 margin-top-bottom-big" >-->
<!--            <ul>-->
<!--              <li>No price restrictions: You can set your own rates and your minimum order price.</li>-->
<!--              <li>No upfront costs: We simply take a commission on top of your rates.</li>-->
<!--              <li>Complete control over your menu: You can showcase your unique style and stand out from the competition.</li>-->
<!--              <li>Flexible for chefs:  You can create your own schedule and work when it suits you</li>-->
<!--              <li>Flexible for clients: Clients can easily book and pay for your services.</li>-->
<!--              <li>Communication: Streamlines communication between clients and chefs, minimizing the need for multiple exchanges.</li>-->
<!--            </ul>-->
<!--          </v-row>-->



          <v-row align-content="center" justify="space-around" class="text-h6 margin-top-bottom-big " >
            <v-col class="col-md-6 col-12">
              <h2 class="primary--text margin-bottom">Be you own boss !</h2>
              <div class="text-h6">
                <ul>
                  <li>No price restrictions, you set your own rates and your minimum order price.</li>
                  <li>No upfront or running costs, we simply take a commission on top of your rates.</li>
                  <li>You create your own schedule and work when it suits you.</li>
                  <li>Manage your own business, with the support of our platform and resources.</li>
                </ul>
              </div>
            </v-col>
            <v-col style="align-self: center" class=" col-md-6 col-12">
              <v-img
                  style="margin: auto"
                  class="round-corner"
                  height="300px"
                  max-width="500px"
                  src="../assets/kootto_private_chef_plate_900.webp"
                  :srcset="`${require('../assets/kootto_private_chef_plate_900.webp')}, ${require('../assets/kootto_private_chef_plate_400.webp')} 500w`"
              />
            </v-col>
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h6 margin-top-bottom-big flex-md-row-reverse"  >
            <v-col class="col-md-6 col-12">
              <h2 class="primary--text margin-bottom">Do what you love !</h2>
              <div class="text-h6">
                <ul>
              <li>You have complete control over your menus.</li>
              <li>Express your creativity and culinary talents by creating unique and personalized menus.</li>
              <li>Build relationships with clients and become a trusted chef for their culinary needs.</li>
              <li>Gain personal satisfaction and fulfillment from sharing your passion for food with others.</li>
                </ul>
              </div>
            </v-col>
            <v-col style="align-self: center" class="col-md-6 col-12" >
              <v-img
                  style="margin: auto"
                  class="round-corner"
                  height="300px"
                  max-width="500px"
                  src="../assets/creation_900.webp"
                  :srcset="`${require('../assets/creation_900.webp')}, ${require('../assets/creation_400.webp')} 500w`"
              />
            </v-col>
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h6 margin-top-bottom-big " >
            <v-col class="col-md-6 col-12" >
              <h2 class="primary--text margin-bottom">Enjoy the benefits !</h2>
              <div class="text-h6">
                <ul>
                  <li>Clients can easily book and pay for your services.</li>
                  <li>Enjoy the convenience of using our platform to manage your bookings, scheduling, and payments.</li>
                  <li>Streamlines communication between clients and chefs, minimizing the need for multiple exchanges.</li>
                  <li>Receive online exposure to a wide audience of potential clients.</li>
                  <li>Presence on social medias</li>
                  <li>Presence on google maps (coming soon)</li>
                  <li>Share your public profile</li>
                </ul>
              </div>
            </v-col>
            <v-col style="align-self: center" class="col-md-6 col-12">
              <v-img
                  style="margin: auto"
                  class="round-corner"
                  height="300px"
                  max-width="500px"
                  src="../assets/table_900.webp"
                  :srcset="`${require('../assets/table_900.webp')}, ${require('../assets/table_400.webp')} 500w`"
              />
            </v-col>
          </v-row>

<!--          <v-row align-content="center" justify="space-around" class="margin-top-bottom-big">-->
<!--            <v-img-->
<!--                height="300px"-->
<!--                max-width="500px"-->
<!--                src="../assets/serving_900.webp"-->
<!--                :srcset="`${require('../assets/serving_900.webp')}, ${require('../assets/serving_400.webp')} 500w`"-->
<!--            />-->

<!--          </v-row>-->

          <div dark style="background-color: black" class="round-corner">
          <v-row align-content="center" justify="space-around" class="secondary-color-text text-h5 padding-top-bottom-default " >
            <div>
              <h2>How it works</h2>
            </div>
          </v-row>

          <v-row align-content="center" justify="space-around" class="margin-top-bottom-big">
            <v-timeline dark :dense="$vuetify.breakpoint.xs" class="margin-default">
              <v-timeline-item color="dark_primary" :icon="$vuetify.breakpoint.xs?'mdi-numeric-1':''" :small="!$vuetify.breakpoint.xs">
                <span slot="opposite"><b class="primary--text">Step 1</b></span>
                <v-card class="elevation-2  padding-left-right-default">
                  <v-card-text style="color:white">
                    Complete your profile (Documents, Menu, Photos ...)
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item color="dark_primary" :icon="$vuetify.breakpoint.xs?'mdi-numeric-2':''" :small="!$vuetify.breakpoint.xs">
                <span slot="opposite"><b class="primary--text">Step 2</b></span>
                <v-card class="elevation-2 padding-left-right-default">
                  <v-card-text style="color:white">
                    Once approved, set your availability, and wait for bookings
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item color="dark_primary" :icon="$vuetify.breakpoint.xs?'mdi-numeric-3':''" :small="!$vuetify.breakpoint.xs">
                <span slot="opposite"><b class="primary--text">Step 3</b></span>
                <v-card class="elevation-2  padding-left-right-default">
                  <v-card-text style="color:white">
                    Accept or reject a booking within 48h
                  </v-card-text>
                </v-card>

              </v-timeline-item>
              <v-timeline-item color="dark_primary" :icon="$vuetify.breakpoint.xs?'mdi-numeric-4':''" :small="!$vuetify.breakpoint.xs">
                <span slot="opposite"><b class="primary--text">Step 4</b></span>
                <v-card dark class="elevation-2  padding-left-right-default">
                  <v-card-text style="color:white">
                    Provide the service and you will get paid automatically
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-row>
          </div>


          <v-row align-content="center" justify="space-around" class="text-h6 margin-default" >
            With our platform, you'll enjoy no price restrictions, complete control over your menu, and the ability to set your own minimum order. Plus, with flexible availability options, you can create your own schedule and work when it suits you. You can also enjoy increased exposure and build your reputation as a top-tier private chef, as well as convenient booking and payment options for clients.
            <br/><br/>
            Imagine being able to attract more clients and increase your revenue while showcasing your talent and unique culinary style. By joining our private chef platform, you can achieve all this and more.
            <br/><br/>
            Don't miss out on this opportunity to grow your private chef business and achieve greater success. Join our platform today and start enjoying the benefits of increased exposure, and convenient booking options. We don't require any upfront costs, and simply take a small commission on top of your rates when you get bookings through our platform
            <br/><br/>
          </v-row>



          <v-row align-content="center" justify="center">
            <br/><br/>
            <v-btn color="primary" href="/search" target="" @click.capture="openSignup(true)">Signup as a chef</v-btn>
          </v-row>

          <v-row align-content="start" class="caption margin-left-right-default" >
            We're here to support you every step of the way, so if you have any questions or need assistance with your application, please don't hesitate to contact us.
          </v-row>
          <v-row align-content="start" justify="" class="caption margin-left-right-default" >
            <div>(*) Please see the details on our <a class="text-decoration-none" href="/terms" @click.prevent="$router.push({path: `/terms`})">Terms and Conditions</a></div>
          </v-row>

        </v-container>

      </div>
    </div>
</template>

<script>

export default {
  name: "PrivateChef",
  components: {
  },
  data: () => ({

  }),
  mounted() {
    this.log(this.$parent);
  },
  methods: {

  }
};
</script>
